import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Mosquitto 2.0 MQTT Broker with Node-RED",
  "path": "/Frequently_Asked_Question/Mosquitto_2.0_with_Node-RED/",
  "dateChanged": "2020-12-13",
  "author": "Mike Polinowski",
  "excerpt": "Mosquitto now offers a management dashboard with version 2. How can I install the broker and use it with my camera and Node-RED?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - Mosquitto 2.0 MQTT Broker with Node-RED' dateChanged='2020-12-13' author='Mike Polinowski' tag='INSTAR IP Camera' description='Mosquitto now offers a management dashboard with version 2. How can I install the broker and use it with my camera and Node-RED?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Mosquitto_2.0_with_Node-RED/' locationFR='/fr/Frequently_Asked_Question/Mosquitto_2.0_with_Node-RED/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`Q`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://mosquitto.org/download/"
      }}>{`Mosquitto now offers a management dashboard`}</a>{` with version 2. How can I install the broker and use it with my camera and Node-RED?`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: Start by `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Mosquitto_2.0_with_Management_Dashboard/"
      }}>{`installing Mosquitto 2.0`}</a>{` on Windows, macOS or LINUX. And add a Mqtt Client for Node-RED inside the Mosquitto Dashboard:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "843px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8c51b96c028c008b0d24ebc98342e915/4ff83/Mosquitto_2.0_with_Node-RED_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABzUlEQVQoz42Rz27TQBCH/SrNiSSoUIV4196N7bU3XttrJ2s3mzhtQoRALW1R0z/qCalUwWlKqUDigCrxdE1FoRx4BoSMlFxI+tN3mMOOvpkdpX8y7R7dbJ/eHU7u+ye3nYNpenibHkznae/fvHn/48O3328//RpmP3fe3R1f3L8+/a6Izc+BvGQiC1qZ0xgRPrLCkR1l85AoC+Q46U3WexfN7sRfH4ftc5aMlTj2jZq69rRYWSs/q5SAuqqBJ+XiyuNSYZ5SsVB8tJKT1+VSQekkLWrZqgoRwrqONB3jmgE1fRHwL5VKVTmy6SbUa6rae94fDAZJHDuOQx8QAICyG0YxwggAHoacc9d1bdt2FiZ/oKqq0ul2TWIBCKSUQoggCBhjS/v/NcdCWJYFIWxEDcYYpbRery8eeGbmnJumCQAQQrRlO+ScWJa9MDOzlDI3h2HUFIIxtvTDZuYkSUzThBAKIWRHUkoJIQ81e56HMdY0zfcDzw+Y5zHXXbz2zJymqWlaEALmmmHgeB4DACCE8pMsMVNaR0hHmGy8+tLcuNrZPb6+/ro/HBqGQSldcmfHcTDSNd1o9c/95OzFy72rj5dbW9sYY0KI9f9Uq9U/QRwEv2ZGOx4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c51b96c028c008b0d24ebc98342e915/e4706/Mosquitto_2.0_with_Node-RED_01.avif 230w", "/en/static/8c51b96c028c008b0d24ebc98342e915/d1af7/Mosquitto_2.0_with_Node-RED_01.avif 460w", "/en/static/8c51b96c028c008b0d24ebc98342e915/c217d/Mosquitto_2.0_with_Node-RED_01.avif 843w"],
              "sizes": "(max-width: 843px) 100vw, 843px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8c51b96c028c008b0d24ebc98342e915/a0b58/Mosquitto_2.0_with_Node-RED_01.webp 230w", "/en/static/8c51b96c028c008b0d24ebc98342e915/bc10c/Mosquitto_2.0_with_Node-RED_01.webp 460w", "/en/static/8c51b96c028c008b0d24ebc98342e915/e3019/Mosquitto_2.0_with_Node-RED_01.webp 843w"],
              "sizes": "(max-width: 843px) 100vw, 843px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c51b96c028c008b0d24ebc98342e915/81c8e/Mosquitto_2.0_with_Node-RED_01.png 230w", "/en/static/8c51b96c028c008b0d24ebc98342e915/08a84/Mosquitto_2.0_with_Node-RED_01.png 460w", "/en/static/8c51b96c028c008b0d24ebc98342e915/4ff83/Mosquitto_2.0_with_Node-RED_01.png 843w"],
              "sizes": "(max-width: 843px) 100vw, 843px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8c51b96c028c008b0d24ebc98342e915/4ff83/Mosquitto_2.0_with_Node-RED_01.png",
              "alt": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Apply the `}<strong parentName="p">{`Client Role`}</strong>{` to this client so that Node-RED is able to subscribe and publish updates to MQTT topics:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d6235b953167c5014e3d8719c67495a0/5a3c9/Mosquitto_2.0_with_Node-RED_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAy0lEQVQY003IQW6DMBCFYc6EISyIlMbM2MyMXdsMhKC0kbKo1PufoCpNpXz6N+9Vx93p9Nb3fV3XXdcZY7ru0LaHtm3Nv6ZpXqcxpjamIiIRISJmds7lnL33pZQYYwgBEBARAJhZhBHAITp8qlR1XdfLTlW3bZt0SimVUlR13l2WpajGMv8Viupyvd0eFTOHEGTHzCklEYkx0ouRWYm/p/nDjXs+EYf3XAGiHewwDPZ8ttYysfdjydna3/MJIAF+Jb0T34k//SjgSaYfvZJT61ZfIvEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6235b953167c5014e3d8719c67495a0/e4706/Mosquitto_2.0_with_Node-RED_02.avif 230w", "/en/static/d6235b953167c5014e3d8719c67495a0/d1af7/Mosquitto_2.0_with_Node-RED_02.avif 460w", "/en/static/d6235b953167c5014e3d8719c67495a0/7f308/Mosquitto_2.0_with_Node-RED_02.avif 920w", "/en/static/d6235b953167c5014e3d8719c67495a0/42df8/Mosquitto_2.0_with_Node-RED_02.avif 1169w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d6235b953167c5014e3d8719c67495a0/a0b58/Mosquitto_2.0_with_Node-RED_02.webp 230w", "/en/static/d6235b953167c5014e3d8719c67495a0/bc10c/Mosquitto_2.0_with_Node-RED_02.webp 460w", "/en/static/d6235b953167c5014e3d8719c67495a0/966d8/Mosquitto_2.0_with_Node-RED_02.webp 920w", "/en/static/d6235b953167c5014e3d8719c67495a0/e28de/Mosquitto_2.0_with_Node-RED_02.webp 1169w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6235b953167c5014e3d8719c67495a0/81c8e/Mosquitto_2.0_with_Node-RED_02.png 230w", "/en/static/d6235b953167c5014e3d8719c67495a0/08a84/Mosquitto_2.0_with_Node-RED_02.png 460w", "/en/static/d6235b953167c5014e3d8719c67495a0/c0255/Mosquitto_2.0_with_Node-RED_02.png 920w", "/en/static/d6235b953167c5014e3d8719c67495a0/5a3c9/Mosquitto_2.0_with_Node-RED_02.png 1169w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d6235b953167c5014e3d8719c67495a0/c0255/Mosquitto_2.0_with_Node-RED_02.png",
              "alt": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we have to configure our MQTT Nodes to use the Mosquitto 2.0 broker. Type in the IP address and port as well as the `}<strong parentName="p">{`Client ID`}</strong>{` you have chosen for Node-RED in the Mosquitto Dashboard:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "839px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e5b8a4ea45594624dfda105a7d7d0d10/d26aa/Mosquitto_2.0_with_Node-RED_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABs0lEQVQoz3WSS2+bQBSF+cXd9sdUilQpfWeRNFVqXIwDhIkZJ8vuqi4YExuMnRnmzcBMa/DCrdpvcXU3R/eco+stsgUA4PHxAUKIENrv97vdrqoqhNCqWCGEimKF8vwZ42aA0oZSWv/4WefIA/f3cXIH4UMcJ3mec85J0zDGOGeccyHEYXDOThBaL168/P7qtRfM409XXy8+T6ZBWBSrhpC63vV9b63tTuj/5Jlg3BDvOvhydnH25vrcD/z1em0Huq5TUhpj3L+w1jrnjDFelIY3weVkdhXOg6fiaTSmlOKMSyHGXQ9IpShjUkqttTGmbVsvmfjfzt/O3n0IfX9TVa1uMcZd1znnlFJ1XRNC6AAhpKoqQsjQCNdae9Po7v3N9OMkmIa3xQoJIXtrtVIYY6XUaHIMYowRQoyej7ZBmoaz2e08jKJoU5ZaKUYpG04xRjnnQ3dHemvHLo/iNE3DMIzjGACwWa8ZY2NCSpveWtP1h4vG/FW4c+6QeblcZlkGIcyyrCxLIQQdPkEKLnVf4lYKxigbWzjlIAYAxEkCIUzTdLvdtm3LGRNcSCmFEO7//Bb/AvT4XSynscUmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5b8a4ea45594624dfda105a7d7d0d10/e4706/Mosquitto_2.0_with_Node-RED_03.avif 230w", "/en/static/e5b8a4ea45594624dfda105a7d7d0d10/d1af7/Mosquitto_2.0_with_Node-RED_03.avif 460w", "/en/static/e5b8a4ea45594624dfda105a7d7d0d10/44676/Mosquitto_2.0_with_Node-RED_03.avif 839w"],
              "sizes": "(max-width: 839px) 100vw, 839px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e5b8a4ea45594624dfda105a7d7d0d10/a0b58/Mosquitto_2.0_with_Node-RED_03.webp 230w", "/en/static/e5b8a4ea45594624dfda105a7d7d0d10/bc10c/Mosquitto_2.0_with_Node-RED_03.webp 460w", "/en/static/e5b8a4ea45594624dfda105a7d7d0d10/0a1be/Mosquitto_2.0_with_Node-RED_03.webp 839w"],
              "sizes": "(max-width: 839px) 100vw, 839px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5b8a4ea45594624dfda105a7d7d0d10/81c8e/Mosquitto_2.0_with_Node-RED_03.png 230w", "/en/static/e5b8a4ea45594624dfda105a7d7d0d10/08a84/Mosquitto_2.0_with_Node-RED_03.png 460w", "/en/static/e5b8a4ea45594624dfda105a7d7d0d10/d26aa/Mosquitto_2.0_with_Node-RED_03.png 839w"],
              "sizes": "(max-width: 839px) 100vw, 839px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e5b8a4ea45594624dfda105a7d7d0d10/d26aa/Mosquitto_2.0_with_Node-RED_03.png",
              "alt": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add the login that you set for the Node-RED client:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "836px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/27b8e/Mosquitto_2.0_with_Node-RED_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABlElEQVQoz5WRXU/bMBSG/U93O5G2CaETV/wYkGBMm0Q7EAVKUkpVtJWtXZNpavsLIM1H05zaTmzHKPaotItJ23NlWXqO3/Ma7Tebtm1bllWr1UzTbDQapmkahmHs7Biv1Ov13S2WZTeb7w4O7L09NBqNJhXjxWKBMc4UYbgMwzAIgnAZPgdBkiR5nlNFXhSbKF5+n0CcoP79YPjwZTh8GI/HALBarQBgo8AYE0KwQt9sAKgQzxPv/s3bp18z9Olz50Pr4rxz7U2nGONlEBBCpJTiL5SiLFiRUpJtAB2eHR61j1pXLd/zGWOccyllFS+nQggpZfmKVPw+lyUlBF057cubs65z6Xk+ACRJQghZp2mWrXVy/SDnHAAyADU355xTSlH35P3N8YnTans//SLPoyhK01RrALBeZ1pmjMVxHEVRNSLLdHno47Vz2rk977re9IduhguhfR1yu+0fsdVq6K7nus7tXa/n+z5RX8UYp7QqWVewlbmi6mwru67b7/cHg8F8PteD5b9RyY+P374qZrPZ/8ovJ2FGCFJfx6gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/e4706/Mosquitto_2.0_with_Node-RED_04.avif 230w", "/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/d1af7/Mosquitto_2.0_with_Node-RED_04.avif 460w", "/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/f73a5/Mosquitto_2.0_with_Node-RED_04.avif 836w"],
              "sizes": "(max-width: 836px) 100vw, 836px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/a0b58/Mosquitto_2.0_with_Node-RED_04.webp 230w", "/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/bc10c/Mosquitto_2.0_with_Node-RED_04.webp 460w", "/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/05152/Mosquitto_2.0_with_Node-RED_04.webp 836w"],
              "sizes": "(max-width: 836px) 100vw, 836px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/81c8e/Mosquitto_2.0_with_Node-RED_04.png 230w", "/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/08a84/Mosquitto_2.0_with_Node-RED_04.png 460w", "/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/27b8e/Mosquitto_2.0_with_Node-RED_04.png 836w"],
              "sizes": "(max-width: 836px) 100vw, 836px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7664ce8e783c7192ca2feaaf6d9e3c11/27b8e/Mosquitto_2.0_with_Node-RED_04.png",
              "alt": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And - if you like - add a Last-Will-and-Testament (`}<strong parentName="p">{`LWT`}</strong>{`) for Node-RED:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/f1901/Mosquitto_2.0_with_Node-RED_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACK0lEQVQoz22Sy3LaMBiF/fLdddXOdNdOZtpp0smlISSkYHB8JzEOxXmALC1qwLYulmzJEq0tyKrfQgvNnNGn8//GY0f4/BxHi8XFzLqwHtL1GkKY5zsA0jRNAQBFWSANhLiqCrB+vb4DqxfDD+bWgxeEkes43y6vvg9u/2QZhBBjXPfwpqnZEUprIVCaLt+9fzUtY2ROTn6cnd9cu65jXg2d4RgWJSGEc77f79X/2CvFlarq2ph4k7Nfp0N74Lju8HJk3s52250Qbdu2CCFKqZSyPSKlVEq1bdvlOTeC6WR2furcXDuuMxrcW/dWkee77bYoS4wxRmi73SKESE+e5xBCpZSUsmkaw7TsH1fDwd3Y8/0ojlarFUIoy7I0TbMsAz1pmm42G3rkrQHD9/3JdGrZThiGy+dl8pLotqSUhGAIYf9sJYTobypKWdM0+jTm87lt277vxXG8XC6TJIGwhBAKIRijhBAd638rGaNN0+gWunAURY9PT9GiQ4cRQpujMFiv9agBAFmWUcoopVVVaXljHgSh5y4e5334d5IkRVFgTJTaV4TAvirduVKyj3XChz9H3jgcfw2n5/ExXJadNuecUYox1tp6VJRSzrns2+7CgT+cmZ9s6ySOD+Fee6M911o7BeseAIAWOWgH/sAyPzrWl7cwhN2ElVKEEAg7bXbQVlVVMcY458e2H366gw/e6PObth6VEG3NWEVI27Z6K5SUdc24EHrJ/oX/AtOy7kVdpLfNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/e4706/Mosquitto_2.0_with_Node-RED_05.avif 230w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/d1af7/Mosquitto_2.0_with_Node-RED_05.avif 460w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/7f308/Mosquitto_2.0_with_Node-RED_05.avif 920w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/62782/Mosquitto_2.0_with_Node-RED_05.avif 942w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/a0b58/Mosquitto_2.0_with_Node-RED_05.webp 230w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/bc10c/Mosquitto_2.0_with_Node-RED_05.webp 460w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/966d8/Mosquitto_2.0_with_Node-RED_05.webp 920w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/2fa1f/Mosquitto_2.0_with_Node-RED_05.webp 942w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/81c8e/Mosquitto_2.0_with_Node-RED_05.png 230w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/08a84/Mosquitto_2.0_with_Node-RED_05.png 460w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/c0255/Mosquitto_2.0_with_Node-RED_05.png 920w", "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/f1901/Mosquitto_2.0_with_Node-RED_05.png 942w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a4e4c7dc3f1eb66b55f9eb12805f0c08/c0255/Mosquitto_2.0_with_Node-RED_05.png",
              "alt": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now make sure that all your MQTT nodes use the new Mosquitto 2.0 broker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "851px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/597782af643550d382a0aa049f1f249a/0fcea/Mosquitto_2.0_with_Node-RED_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABKklEQVQY02WR3W6CMBiGudGd7mJ2BbuFJZuI7ocSgWRniqA737KDiTLqREtL/8sClWSZT540TZO339vUubkf3U6eksUiWyZpkmTLZD5fbPOcEFJjjAbqHowQNeb97uH16hp+fDqhOwndyWwwHHnPo2m++frZ7yGESimttZRSDTDGYFEcYXk4HJzAC4AHrIEXBGPwOAZF8S2l1D2MMSmlGbB3tW3bNI0DxsB3favdT12wybeoRlprzvmxqjDGnHPWQyklhLRtSyl1YhBHfmSN/Sh6CWd+vNsVnDFjjBCiIaRpGjKshBBK6Tm8flv/M1tlEELGmNZaKSWtfdW/dLVXFyzTtCxLjLGUUghRVdWpB6GTEMIeGmO6yZfhdAh3b2YM1ed/shBMtNa29i/2tKj4KgtbZgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/597782af643550d382a0aa049f1f249a/e4706/Mosquitto_2.0_with_Node-RED_06.avif 230w", "/en/static/597782af643550d382a0aa049f1f249a/d1af7/Mosquitto_2.0_with_Node-RED_06.avif 460w", "/en/static/597782af643550d382a0aa049f1f249a/7aa96/Mosquitto_2.0_with_Node-RED_06.avif 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/597782af643550d382a0aa049f1f249a/a0b58/Mosquitto_2.0_with_Node-RED_06.webp 230w", "/en/static/597782af643550d382a0aa049f1f249a/bc10c/Mosquitto_2.0_with_Node-RED_06.webp 460w", "/en/static/597782af643550d382a0aa049f1f249a/155dd/Mosquitto_2.0_with_Node-RED_06.webp 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/597782af643550d382a0aa049f1f249a/81c8e/Mosquitto_2.0_with_Node-RED_06.png 230w", "/en/static/597782af643550d382a0aa049f1f249a/08a84/Mosquitto_2.0_with_Node-RED_06.png 460w", "/en/static/597782af643550d382a0aa049f1f249a/0fcea/Mosquitto_2.0_with_Node-RED_06.png 851w"],
              "sizes": "(max-width: 851px) 100vw, 851px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/597782af643550d382a0aa049f1f249a/0fcea/Mosquitto_2.0_with_Node-RED_06.png",
              "alt": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Deploy your Node-RED flow and check the Mosquitto Dashboard. You should now see the `}<strong parentName="p">{`LWT`}</strong>{` in the MQTT `}<strong parentName="p">{`Topic Tree`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d3fefedd5376fb65a5535c74d76e915f/32ac3/Mosquitto_2.0_with_Node-RED_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAByUlEQVQoz2WQS24TQRCG50a25MQed/X0o7q63Y/pHitEvIzEEjhANglrNiyyQWwgyOIA3CJLuIc3joS8tDLIEyPx+Db1q6RP9auqlff8dDo5OanrejabjUaj8Xg8Gg9j4LgZGP9N9YqcmzOy1hiUUjZNw7gQMCQh6ro2xgghENEYAwDNbwCgKikxxnLOIQTUGpF8FJ9l+3IehDMN5yEEIgohlFLatrXWIiIRKaWqNmfGmHPuIKMCYV+vTm+zX50k4TQHSCktFs5733Xdg09EiIeaVYwRABAxphiCF9I86uBrxhfzhbDIAWKMlsgO0BCMMUe5lAIAWuuUUilFSSSvPj1uns6dHOQQgnPuQdADOHC8zDlHxBRT8F5I7DL/dkbPZkFaBAbL5TLnrP5DCPGHnJJzTim0Xn5cqhVzkg5yzjmGYK2NOccY00DbtohYSSlh6FZKRq1QKUXiXYLzxjF7+LbW+nQ6/XB9/fP7j81mc3d3t91ud7vdxcVFpbUGACJqy/kinJHt0Je3nXk/4c8bUTcNGTOZTNbrdd/3+/2+7/v7+/u+76+urg7yQ21DnlwyNhobg1u8YfyJkDPOu5LZfH5z8+Uf+fLy8hfQNtJywryMCAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d3fefedd5376fb65a5535c74d76e915f/e4706/Mosquitto_2.0_with_Node-RED_07.avif 230w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/d1af7/Mosquitto_2.0_with_Node-RED_07.avif 460w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/7f308/Mosquitto_2.0_with_Node-RED_07.avif 920w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/92269/Mosquitto_2.0_with_Node-RED_07.avif 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d3fefedd5376fb65a5535c74d76e915f/a0b58/Mosquitto_2.0_with_Node-RED_07.webp 230w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/bc10c/Mosquitto_2.0_with_Node-RED_07.webp 460w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/966d8/Mosquitto_2.0_with_Node-RED_07.webp 920w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/c7643/Mosquitto_2.0_with_Node-RED_07.webp 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d3fefedd5376fb65a5535c74d76e915f/81c8e/Mosquitto_2.0_with_Node-RED_07.png 230w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/08a84/Mosquitto_2.0_with_Node-RED_07.png 460w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/c0255/Mosquitto_2.0_with_Node-RED_07.png 920w", "/en/static/d3fefedd5376fb65a5535c74d76e915f/32ac3/Mosquitto_2.0_with_Node-RED_07.png 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d3fefedd5376fb65a5535c74d76e915f/c0255/Mosquitto_2.0_with_Node-RED_07.png",
              "alt": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "title": "Mosquitto 2.0 MQTT Broker mit Management Dashboard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As a demo I `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/HTML5_Video_mit_Node-RED_Dashboard/"
      }}>{`added my cameras HTML5 Video`}</a>{` and toggles for my `}<a parentName="p" {...{
        "href": "//Web_User_Interface/1080p_Series/Multimedia/Privacy_Mask/"
      }}>{`cameras privacy masks`}</a>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/4a606ce9000ed3e1bd21d9008e98dedf/Mosquitto_2.0_with_Node-RED_08.gif",
        "alt": "Mosquitto 2.0 MQTT Broker mit Management Dashboard"
      }}></img></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      